import request from '../utils/request';

export function whiteList(query) {
	return request({
		url: "whitelist",
		method: "get",
		params: query
	});
}

export function whitelistSave(data) {
	return request({
		url: "whitelist",
		method: "post",
		data: data
	});
}

export function whiteState(query) {
	return request({
		url: 'whitelist/state',
		method: 'POST',
		data: query
	});
}

export function whiteEnable(query) {
	return request({
		url: 'whitelist/batch_enable',
		method: 'POST',
		data: query
	});
}
export function whiteDisable(query) {
	return request({
		url: 'whitelist/batch_disable',
		method: 'POST',
		data: query
	});
}
export function whiteDelete(query) {
	return request({
		url: 'whitelist/batch_delete',
		method: 'POST',
		data: query
	});
}
export function setInit(query) {
	return request({
		url: 'whitelist/set_init',
		method: 'POST',
		data: query
	});
}

export function download() {
    return request({
		url: 'whitelist/download',
        method: 'get',
    })
}

export function uploadUrl(query) {
    return request({
        url: "whitelist/import",
        method: "post",
        headers:{'Content-Type':'multipart/form-data'},
        data: query
    });
}