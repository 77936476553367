<template>
	<div class="conbox">
		<div class="sub_til" style="margin-top: 20px;">学生网络白名单</div>
		<el-form :inline="true" :model="query" class="query-form" size="mini" style="float: left;">
			<el-form-item class="query-form-item">
				<el-input v-model="query.name" placeholder="姓名"></el-input>
			</el-form-item>
			<el-form-item class="query-form-item">
				<el-input v-model="query.mobile" placeholder="手机号"></el-input>
			</el-form-item>
			<el-form-item>
				<el-button-group>
					<el-button type="primary" icon="el-icon-refresh" @click="onReset()"></el-button>
					<el-button type="primary" icon="search" @click="onSubmit()">查询</el-button>
				</el-button-group>
			</el-form-item>
		</el-form>
		<el-button-group style="margin-bottom: 15px; float: right;">
			<el-button type="warning" @click="changeForm(null, null)" size="mini">一键初始化</el-button>
			<el-button type="primary" @click="handleForm(null, null)" size="mini">新增白名单</el-button>
			<!-- 	<el-upload :http-request="uploadFile" ref='uploadFile' class="upload-demo">
				<el-input v-model="file_url" style="display: none"></el-input>
				<el-button type="primary" @click="uploadFile()" size="mini">导入Excel</el-button>
			</el-upload> -->
			<el-button type="primary" @click="download()" size="mini">下载Excel模板</el-button>
			<el-upload :http-request="uploadFile">
				<el-button size="mini" type="primary">导入Excel</el-button>
			</el-upload>
			<el-button type="primary" @click="$router.push({name:'whiterecord'})" size="mini">导入记录</el-button>
			<el-button type="success" @click="handleEnable(null, null)" size="mini">批量启用</el-button>
			<el-button type="warning" @click="handleDisable(null, null)" size="mini">批量停用</el-button>
			<el-button type="danger" @click="handleDelete(null, null)" size="mini">批量删除</el-button>
		</el-button-group>
		<el-table v-loading="loading" :data="list" style="width: 100%" max-height="500"
			@selection-change="handleSelectionChange" ref="multipleTable" border>
			<el-table-column type="selection" width="55"></el-table-column>
			<el-table-column label="姓名" prop="name"></el-table-column>
			<el-table-column label="手机号" prop="mobile"> </el-table-column>
			<el-table-column label="身份证号" prop="id_card"> </el-table-column>
			<el-table-column label="录入日期" prop="create_time"> </el-table-column>
			<el-table-column label="绑定日期" prop="binding_time"> </el-table-column>
			<el-table-column label="到期日期" prop="expiration_time"> </el-table-column>
			<el-table-column label="账号数量" prop="account_num"> </el-table-column>
			<el-table-column label="状态" prop="status">
				<template #default="scope">
					<el-tag :type="scope.row.status == '已停用' ? 'danger' : 'success'" disable-transitions>
						{{scope.row.status == '已停用' ? '已停用' : '已启用'}}
					</el-tag>
				</template>
			</el-table-column>
			<el-table-column label="操作" fixed="right">
				<template #default="scope">
					<el-button type="text" size="small" icon="el-icon-edit"
						@click="$router.push({name:'accountlist',query:{id:scope.row.id}})">账号管理</el-button>
					<el-button type="text" size="small" @click="handleState(scope.$index, scope.row)" class="red"
						v-if="scope.row.status=='已停用'">启用
					</el-button>
					<el-button type="text" size="small" @click="handleState(scope.$index, scope.row)" v-else>停用
					</el-button>
				</template>
			</el-table-column>
		</el-table>
		<div class="pagination">
			<el-pagination background layout="total, prev, pager, next" :current-page="query.page"
				:page-size="query.pageSize" :total="total" @current-change="handlePageChange"></el-pagination>
		</div>
		<!--新增弹窗内容-->
		<el-dialog :title="formTil" v-model="formVisible" :before-close="hideForm" width="85%" top="5vh">
			<el-form :model="formData" :rules="formRules" ref="dataForm">
				<el-form-item label="姓名" prop="name">
					<el-input v-model="formData.name" auto-complete="off"></el-input>
				</el-form-item>
				<el-form-item label="手机号" prop="mobile">
					<el-input v-model="formData.mobile" auto-complete="off"></el-input>
				</el-form-item>
				<el-form-item label="身份证号" prop="id_card">
					<el-input v-model="formData.id_card" auto-complete="off"></el-input>
				</el-form-item>
				<el-form-item label="备注" prop="remark">
					<el-input type="textarea" v-model="formData.remark"></el-input>
				</el-form-item>
			</el-form>
			<div class="dialog-footer">
				<el-button @click="hideForm">取消</el-button>
				<el-button type="primary" @click="formSubmit()" :loading="formLoading">提交</el-button>
			</div>
		</el-dialog>
	</div>
</template>

<script>
	import {
		whiteList,
		whitelistSave,
		whiteState,
		whiteEnable,
		whiteDisable,
		setInit,
		download,
		uploadUrl,
		whiteDelete
	} from "../api/whitelist";
	const formJson = {};
	export default {
		data() {
			return {
				query: {
					page: 1,
					pageSize: 15,
				},
				ids: [],
				list: [],
				action: '6665',
				total: 0,
				loading: true,
				formTil: "新增",
				formLoading: false,
				formVisible: false,
				formData: formJson,
				formRules: {
					name: [{
						required: true,
						message: "请输入姓名",
						trigger: "blur"
					}],
					mobile: [{
						required: true,
						message: "请输入手机号",
						trigger: "blur"
					}],
					// id_card: [{
					// 	required: true,
					// 	message: "请输入身份证号",
					// 	trigger: "blur"
					// }]
				},
				deleteLoading: false,
			};
		},
		methods: {
			onReset() {
				this.$router.push({
					path: "",
				});
				this.query = {
					page: 1,
					pageSize: 15,
				};
				this.getList();
			},
			onSubmit() {
				this.$router.push({
					path: "",
					query: this.query,
				});
				this.getList();
			},
			handlePageChange(val) {
				this.query.page = val;
				this.getList();
			},
			getList() {
				this.loading = true;
				whiteList(this.query)
					.then((res) => {
						this.loading = false;
						// console.log(res.data.data)
						this.list = res.data.data || [];
						this.total = res.data.total || 0;
					})
					.catch(() => {
						this.loading = false;
						this.list = [];
						this.total = 0;
					});
			},
			download() {
				download().then((res) => {
					// console.log(res)
					// this.daochu_data = process.env.VUE_APP_SERVICE_URL + res.data.file;
					this.daochu_data = res.data.file;
					//let url = window.URL.createObjectURL(new Blob([this.daochu_data]));
					let url = this.daochu_data;
					let link = document.createElement("a");
					link.style.display = "none";
					link.href = url;
					//link.setAttribute("download",  "new_name.xls");
					document.body.appendChild(link);
					link.click();
				});
			},
			uploadFile(e) {
				console.log(e)
				const file = e.file;
				const params = new FormData();
				params.append("file", file);
				this.$message.info("上传中…");
				this.loading = true;
				uploadUrl(params).then((res) => {
					if (res.code == 200) {
						console.log(res)
						//this.addRules.face_img = [];
						//this.$refs["uploadElement"].clearValidate();
						this.$message.success("上传成功");
						this.file_url = res.data.file;
						this.getList();
					} else {
						// this.$message.error("上传失败");
						this.$message.error(res.msg)
					}
				});
			},
			// 隐藏表单
			hideForm() {
				// 更改值
				this.formVisible = !this.formVisible;
				// 清空表单
				this.$refs["dataForm"].resetFields();
				return true;
			},
			// 显示表单
			handleForm(index, row) {
				this.formVisible = true;
				this.formData = JSON.parse(JSON.stringify(formJson));
				if (row !== null) {
					this.formData = Object.assign({}, row);
				}
			},
			formSubmit() {
				this.$refs["dataForm"].validate((valid) => {
					if (valid) {
						this.formLoading = true;
						let data = Object.assign({}, this.formData);
						whitelistSave(data, this.formName).then((res) => {
							this.formLoading = false;
							if (res.code != 200) {
								this.$message.error(res.msg);
								return false;
							}
							this.$message.success("操作成功");
							this.formVisible = false;
							this.getList();
						});
					}
				});
			},
			// 停用/启用
			handleState(index, row) {
				this.$confirm("您确定要操作吗？", "提示", {
						type: "warning",
					})
					.then(() => {
						whiteState({
							id: row.id
						}).then((res) => {
							if (res.code == 200) {
								this.$message.success(res.msg);
								this.getList();
								return;
							} else {
								this.$message.error(res.msg);
								return;
							}
						});
					})
					.catch(() => {});
			},

			handleSelectionChange(val) {
				this.ids = [];
				// this.multipleSelection = val;
				// console.log(val)
				val.forEach(row => {
					// console.log(row.id)
					this.ids.push(row.id)
				});
				// console.log(this.ids)
			},
			// 批量启用
			handleEnable() {
				this.$confirm("您确定要批量启用吗？", "提示", {
						type: "warning",
					})
					.then(() => {
						this.$message.info("批量启用中…");
						this.loading = true;
						// console.log(this.ids);
						let data = {
							ids: this.ids,
						}
						whiteEnable(data).then((res) => {
							console.log(res);
							if (res.code == 200) {
								this.$message.success("启用成功");
								this.getList();
								return;
							} else {
								this.$message.error(res.msg);
								return;
							}
						});
					})
					.catch(() => {});
			},
			// 批量停用
			handleDisable() {
				this.$confirm("您确定要批量停用吗？", "提示", {
						type: "warning",
					})
					.then(() => {
						this.$message.info("批量停用中…");
						this.loading = true;
						// console.log(this.ids);
						let data = {
							ids: this.ids,
						}
						whiteDisable(data).then((res) => {
							console.log(res);
							if (res.code == 200) {
								this.$message.success("停用成功");
								this.getList();
								return;
							} else {
								this.$message.error(res.msg);
								return;
							}
						});
					})
					.catch(() => {});
			},
			// 批量删除
			handleDelete() {
				this.$confirm("您确定要批量删除吗？", "提示", {
						type: "warning",
					})
					.then(() => {
						this.$message.info("批量删除中…");
						this.loading = true;
						// console.log(this.ids);
						let data = {
							ids: this.ids,
						}
						whiteDelete(data).then((res) => {
							console.log(res);
							if (res.code == 200) {
								this.$message.success("删除成功");
								this.getList();
								return;
							} else {
								this.$message.error(res.msg);
								return;
							}
						});
					})
					.catch(() => {});
			},
			// 一键初始化
			changeForm() {
				this.$confirm("您确定要一键初始化所有用户的密码吗？", "提示", {
						type: "warning",
					})
					.then(() => {
						// console.log(this.action);
						let data = {
							action: '6665',
						}
						setInit(data).then((res) => {
							console.log(res);
							if (res.code == 200) {
								this.$message.success(res.msg);
								this.getList();
								return;
							} else {
								this.$message.error(res.msg);
								return;
							}
						});
					})
					.catch(() => {});
			},
		},
		mounted() {},
		created() {
			this.getList();
		},
	};
</script>

<style>
	.red {
		color: #ff0000;
	}

	.red:hover {
		color: #ff0000;
	}

	.red:active {
		color: #ff0000;
	}

	.el-button-group div {
		float: left;
	}

	.el-upload-list {
		display: none;
	}

	.el-upload--text {
		width: auto;
		height: auto;
		border: none;
		border-radius: 0;
	}
</style>
